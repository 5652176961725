<script>
import { Bar } from 'vue-chartjs'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips'

export default {
  extends: Bar,
  props: ['height'],
  data: function () {
    return {
      datasetsApi : [
      {
        label: 'Annotation',
        backgroundColor: 'rgba(255,255,255,.3)',
        borderColor: 'transparent',
        data: [],
        labels: []
      }
    ]

    }
  },
  mounted () {

    this.$store.dispatch('dashboard/GET_REKAP_ANNOTATION_IMAGE',{masterType: 'dashboard'}).then(()=>{
        let dataFromRest = (this.$store.getters['dashboard/rekapAnnotationImage']) ? this.$store.getters['dashboard/rekapAnnotationImage'] : []
        let dataTemp = []
        let labelTemp = []
        for (let item of Object.values(dataFromRest)) {
          for (let valueItem of Object.keys(item)) {
            this.datasetsApi[0].data.push(item[valueItem])
            labelTemp.push(valueItem)
          }
        }


        this.renderChart(
          {
            labels:labelTemp,
            datasets: this.datasetsApi
          },
          {
            tooltips: {
              enabled: false,
              custom: CustomTooltips
            },
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            scales: {
              xAxes: [{
                display: false,
                categoryPercentage: 1,
                barPercentage: 0.5
              }],
              yAxes: [{
                display: false
              }]
            }
          }
        )
    })
  }
}
</script>
